// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.demo .menu {
    position: relative;
    float: left;
    min-height: 100%;
    border-right: 1px solid #e6e6e6;
}

.demo .content {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.demo .links {
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #00000050;
    padding: 0 3px;
    text-decoration: none;
    font-size: 12px;
}

.demo .links a {
    cursor: pointer;
    color: white;
}

.demo .links a:hover {
    text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/demo/index.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,2BAA2B;IAC3B,cAAc;IACd,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".demo {\r\n    width: 100%;\r\n    height: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.demo .menu {\r\n    position: relative;\r\n    float: left;\r\n    min-height: 100%;\r\n    border-right: 1px solid #e6e6e6;\r\n}\r\n\r\n.demo .content {\r\n    position: relative;\r\n    height: 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.demo .links {\r\n    position: absolute;\r\n    bottom: 0px;\r\n    left: 0px;\r\n    background-color: #00000050;\r\n    padding: 0 3px;\r\n    text-decoration: none;\r\n    font-size: 12px;\r\n}\r\n\r\n.demo .links a {\r\n    cursor: pointer;\r\n    color: white;\r\n}\r\n\r\n.demo .links a:hover {\r\n    text-decoration: underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
