import "./index.css";
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {useEffect, useRef, useState} from "react";
import { Link, useParams } from 'react-router-dom';

interface MenuProp {
    title: string;
    url: string;
}

interface MenusProp {
    title: string;
    subMenus: MenuProp[];
}

function Examples() {
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [title, setTitle] = useState<string>("");
    const [indexData, setIndexData] = useState<string[]>([]);
    const [data, setData] = useState<MenusProp[]>([]);
    const iframeRef = useRef<HTMLIFrameElement>();
    const { id } = useParams();

    const run = async (code: string) => {
        const preview = document.querySelector("#preview") as HTMLElement;
        preview.innerHTML = "";
        iframeRef.current = document.createElement("iframe") as HTMLIFrameElement;
        preview.appendChild(iframeRef.current);
        if(code) {
            debugger
            const doc = iframeRef.current.contentWindow?.document;
            if (doc) {
                if(id == "Viewer"){
                    
                    const hash = doc.baseURI;
                    if (!hash) return null;
                    
                    // Parse the hash string
                    const hashParts = hash.split('?');
                    if (hashParts.length < 2) return null;
                    if (hashParts[1].includes("Fileid")){
                        var str = hashParts[1].slice(7);
                    //ar key ="MIICXQIBAAKBgQDlOJu6TyygqxfWT7eLtGDwajtNFOb9I5XRb6khyfD1Yt3YiCgQWMNW649887VGJiGr/L5i2osbl8C9+WJTeucF+S76xFxdU6jE0NQ+Z+zEdhUTooNRaY5nZiu5PgDB0ED/ZKBUSLKL7eibMxZtMlUDHjm4gwQco1KRMDSmXSMkDwIDAQABAoGAfY9LpnuWK5Bs50UVep5c93SJdUi82u7yMx4iHFMc/Z2hfenfYEzu+57fI4fvxTQ//5DbzRR/XKb8ulNv6+CHyPF31xk7YOBfkGI8qjLoq06V+FyBfDSwL8KbLyeHm7KUZnLNQbk8yGLzB3iYKkRHlmUanQGaNMIJziWOkN+N9dECQQD0ONYRNZeuM8zd8XJTSdcIX4a3gy3GGCJxOzv16XHxD03GW6UNLmfPwenKu+cdrQeaqEixrCejXdAFz/7+BSMpAkEA8EaSOeP5Xr3ZrbiKzi6TGMwHMvC7HdJxaBJbVRfApFrE0/mPwmP5rN7QwjrMY+0+AbXcm8mRQyQ1+IGEembsdwJBAN6az8Rv7QnD/YBvi52POIlRSSIMV7SwWvSK4WSMnGb1ZBbhgdg57DXaspcwHsFV7hByQ5BvMtIduHcT14ECfcECQATeaTgjFnqE/lQ22Rk0eGaYO80cc643BXVGafNfd9fcvwBMnk0iGX0XRsOozVt5AzilpsLBYuApa66NcVHJpCECQQDTjI2AQhFc1yRnCU/YgDnSpJVm1nASoRUnU8Jfm3Ozuku7JUXcVpt08DFSceCEX9unCuMcT72rAQlLpdZir876";
                    //initializeEncryption(key,'1234567890abcdef');
                    const key = "]n))#PwSYjo)K8se_FLWDe/]zYY3=2RU";
                    // Decrypt the data
                    const encryptedData = str;
                    try {
                        debugger
                        const decrypted = encryptedData;//decryptData(encryptedData, key);
                        const str = decrypted;
                        const beforeChar = str.substring(0, str.indexOf('_'));
                        const afterChar = str.slice(str.indexOf('_') + 1);
                        //useEffect(() => {
                            const xhr = new XMLHttpRequest();
                            xhr.open('GET', 'https://rest.ifieldsmart.com/api/FolderManagement/Get6DFileDetails?ProjectID='+beforeChar+'&FileID='+afterChar);
                            xhr.responseType = 'json'; 
                            xhr.onload = async function() {
                            if (xhr.status === 200) {
                                debugger
                                const res = xhr.response; // Directly get the JSON response//JSON.parse(xhr.responseText);
                                if (res.length > 0){
                                    const gltfUrl = res[0].fbxpath;
                
                                    // Fetch the GLTF file and convert to blob
                                    const gltfResponse = await fetch(gltfUrl);
                                    if (!gltfResponse.ok) {
                                        throw new Error(`Failed to fetch the GLTF file. Status: ${gltfResponse.status}`);
                                    }
                
                                    //////const blob = await gltfResponse.blob();
                                    // const reader = new FileReader();

                                    // reader.onload = function(event) {
                                    //     if (event.target) {
                                    //         const result = event.target?.result; // `result` is `string | ArrayBuffer | null`
                                    //         if (typeof result === 'string') {
                                    //             localStorage.setItem('gltfpath', result);
                                    //             console.log(result); // Now `result` is guaranteed to be a `string`
                                    //             // Use `result` as needed (e.g., passing it to a function that expects a string)
                                    //         } else {
                                    //             console.error('Result is not a string');
                                    //         }
                                    //     } else {
                                    //         console.error('Event target is null');
                                    //     }
                                    // };

                                    // // Read the blob as a data URL or text
                                    // reader.readAsDataURL(blob); // or re
                                    // const blob = await gltfResponse.blob();
                                     //////const localUrl = URL.createObjectURL(blob);
                
                                    // // Store the local URL or path in local storage
                                     localStorage.setItem('gltfpath',res[0].fbxpath );//////localUrl
                                    doc.open();
                                    doc.write(code);
                                    doc.close();
                                // const blob = await res[0].gltfpath.blob();
                                // const localUrl = URL.createObjectURL(blob);
                                // localStorage.setItem('gltfpath', JSON.stringify(res[0].gltfpath));
                                //console.log(JSON.parse(xhr.responseText));
                                }
                                
                            }
                            };
                            xhr.send();
                        // const gltfUrl = str;//"https://ifieldsmart.s3.us-east-1.amazonaws.com/revirahotel1010201814343275/F12207201904421011/SixD/0603202313000838Audubon_Arch.glb";
                    
                        //                 // Fetch the GLTF file and convert to blob
                        //                 const gltfResponse = await fetch(gltfUrl);
                        //                 if (!gltfResponse.ok) {
                        //                     throw new Error(`Failed to fetch the GLTF file. Status: ${gltfResponse.status}`);
                        //                 }
                    
                        //                 const blob = await gltfResponse.blob();
                        //                 const localUrl = URL.createObjectURL(blob);
                    
                        //                 // Store the local URL or path in local storage
                        //                 localStorage.setItem('gltfpath', 'http://localhost:3002/30355f09-568d-484c-93a6-2b1eb01845fc');
                        // //localStorage.setItem('gltfpath', "https://ifieldsmart.s3.us-east-1.amazonaws.com/revirahotel1010201814343275/F12207201904421011/SixD/0603202313000838Audubon_Arch.glb");
                        //                 doc.open();
                        //                 doc.write(code);
                        //                 doc.close();
                        // if (decrypted) {
                        //     const str = decrypted;
                        //     const beforeChar = str.substring(0, str.indexOf('_'));
                        //     const afterChar = str.slice(str.indexOf('_') + 1);
                        //     //useEffect(() => {
                        //         const xhr = new XMLHttpRequest();
                        //         xhr.open('GET', 'http://localhost:55126/api/FolderManagement/Get6DFileDetails?ProjectID='+beforeChar+'&FileID='+afterChar);
                        //         xhr.onload = async function() {
                        //         if (xhr.status === 200) {
                        //             const res = JSON.parse(xhr.responseText);
                        //             const gltfUrl = res[0].gltfpath;
                    
                        //                 // Fetch the GLTF file and convert to blob
                        //                 const gltfResponse = await fetch(gltfUrl);
                        //                 if (!gltfResponse.ok) {
                        //                     throw new Error(`Failed to fetch the GLTF file. Status: ${gltfResponse.status}`);
                        //                 }
                    
                        //                 const blob = await gltfResponse.blob();
                        //                 const localUrl = URL.createObjectURL(blob);
                    
                        //                 // Store the local URL or path in local storage
                        //                 localStorage.setItem('gltfpath', localUrl);
                        //                 doc.open();
                        //                 doc.write(code);
                        //                 doc.close();
                        //             // const blob = await res[0].gltfpath.blob();
                        //             // const localUrl = URL.createObjectURL(blob);
                        //             // localStorage.setItem('gltfpath', JSON.stringify(res[0].gltfpath));
                        //             //console.log(JSON.parse(xhr.responseText));
                        //         }
                        //         };
                        //         xhr.send();
                        //         // const response = await getFile(afterChar,beforeChar);
                        //         // if(response != undefined){
                        //         //     doc.open();
                        //         //     doc.write(code);
                        //         //     doc.close();
                        //         // }
                                
                        // //}, []);
                        // } else {
                        //     console.log('Decryption returned null or failed.');
                        // }
                    } catch (error) {
                        console.error('Decryption process failed:', error);
                    }
                    }
                    else{
                        var str = hashParts[1].slice(4);
                        localStorage.setItem('gltfpath', str);
                                    
                        doc.open();
                        doc.write(code);
                        doc.close();
                    }
                    //const decryptedData = decrypt(encryptedData);
                    
                    //console.log('Decrypted Data:', decryptedData);
                    // const params = new URLSearchParams(hashParts[1]);
                    // console.log(params);
                    }
                
            }
            // if (doc) {
            //     alert();
            //     doc.open();
            //     doc.write(code);
            //     doc.close();
            // }
        }
    }

    const getCode = (url: string) => {
        if (!url) return;
        fetch(url, {mode: 'cors'}).then(data => data.text()).then((data) => {
            run(data);
        })
    }

    useEffect(() => {
        fetch("./config.json").then(data => data.json()).then((data) => {
            const indexMenus:string[]  = [];
            data.forEach((menu: MenusProp) => {
                indexMenus.push(menu.title);
            });
            setIndexData(indexMenus);
            setData(data);
        });
    }, [])

    useEffect(() => {
        if (!id) {
            return;
        }
        const subTitle = id.split("_").join(" ");
        data.forEach((menu: MenusProp) => {
            menu.subMenus.forEach((subMenu: MenuProp) => {
                if (subMenu.title === subTitle) {
                    getCode(subMenu.url);
                    setTitle(subMenu.title);
                }
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, data])


    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    const scrollToTarget = (target: string) => {
        const element = document.getElementById(target.split(" ").join("_"));
        element?.scrollIntoView();
    };

    return (
        <div className="examples">
            <button className={`index-button${collapsed ? " open" : ""}`} onClick={toggleCollapsed}>
                {!collapsed ?  <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </button>
            <div className={`page-title${collapsed ? " open" : ""}`}>{title}</div>
            {/* <div className={`content-overlay${collapsed? " open" : ""}`} onClick={toggleCollapsed}></div> */}
            <div className={`content-container${collapsed ? " open" : ""}`} id="preview"></div>
            <div className={`menu${collapsed ? " open" : ""}`}>
                <div id="index">
                    <h1>Examples</h1>
                    <div>
                        <h2>Index</h2>
                        <hr/>
                        {
                            indexData.map((title: string, index: number) => {
                                return (
                                    <div className="link" key={`${title}-${index}`} onClick={() => scrollToTarget(title)}>
                                        {`${index}....${title}`}
                                    </div>
                                );
                            })
                        }
                        <hr/>
                        {
                            data.map((menu: MenusProp, index: number) => {
                                return (
                                    <div key={`${menu.title}_${index}`}>
                                        <h2 id={menu.title.split(" ").join("_")}>{`${index}.${menu.title}`}</h2>
                                        <hr/>
                                        {
                                            menu.subMenus.map((subMenu: MenuProp, subIndex: number) => {
                                                return (
                                                    <div className="link" key={`${subMenu.title}_${subIndex}`}>
                                                        <Link to={`${subMenu.title.split(" ").join("_")}`}>{subMenu.title}</Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="links">
                    <a href="https://pattern-x.github.io/gemini-viewer-examples/#/demo/">Demos</a>
                    &nbsp;|&nbsp;
                    <a href="https://github.com/pattern-x/gemini-viewer-examples">Github repo</a>
                </div>
            </div>
        </div>
    )
}

export default Examples;