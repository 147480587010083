// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavMenu {
    width: 220px;
    transition: ease 0.3s;
    transition-property: width;
}

.NavMenu.active {
    width: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/demo/components/NavMenu/NavMenu.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,qBAAqB;IACrB,0BAA0B;AAC9B;;AAEA;IACI,QAAQ;AACZ","sourcesContent":[".NavMenu {\r\n    width: 220px;\r\n    transition: ease 0.3s;\r\n    transition-property: width;\r\n}\r\n\r\n.NavMenu.active {\r\n    width: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
