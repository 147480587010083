// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Manrope", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  height: 1px;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .1);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,6BAA6B;EAC7B,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,UAAU;AACZ;AACA;EACE,6BAA6B;EAC7B,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["html, body, #root {\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow: hidden;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: \"Manrope\", serif;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  height: 1px;\r\n  width: 5px;\r\n}\r\n::-webkit-scrollbar-thumb {\r\n  background: rgba(0, 0, 0, .1);\r\n  border-radius: 10px;\r\n}\r\n::-webkit-scrollbar-track {\r\n  background: #ededed;\r\n  border-radius: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
